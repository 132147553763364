import "normalize.css";
import {Routes, Route, useLocation} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import React, { Suspense } from "react";
import AuthContext from "./store/auth-context";
import ModalsContext from "./store/modals-context";
import { ApplicationMobileProvide } from "./store/app-mobile-context";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Modal from "./UI/Modal/Modal";
import CabinetMainPage from "./cabinet/mainPage/CabinetMainPage";
import Admin from "./admin/Admin";
import MobileApp from "./UI/Modal/MobileApp";
import NoInternetConnection from "./UI/NoInternetConnection";
import AdminLogin from "./components/login/AdminLogin.jsx/AdminLogin";
import  {link} from "./store/host-store";
import ModeratorLayout from "./moderator/main/ModeratorLayout";
import { ModeratorContextProvide } from "./store/moderator-context";
import Loader from "./components/loader/Loader";
import UseLazy from "./store/hooks/useLazy";
import SearchPage from "./components/searching/SearchPage";
import BankCheck from "./UI/BankCheck";
import NotFound from "./components/404/NotFound";
import { Helmet } from "react-helmet";
import LoginMain from "./components/login/LoginMain";
import Advertiser from "./adRole/Advertiser";
import AdModeratorLayout from "./adModerator/AdModeratorLayout";
import {AdModeratorContextProvide} from "./store/ad-moderator-context";
import DeviceContext from "./store/device-context";
import StubAds from "./cabinet/advertisement/stub/StubAds";
import {PartnerProgram} from "./simplePages/partnerProgram/PartnerProgram";

const ReferralInfo = UseLazy("simplePages/UsersInfo/referral/ReferralInfo");
const AdministrantsInfo = UseLazy("simplePages/UsersInfo/administrantsInfo/AdministrantsInfo");
const CustomersInfo = UseLazy("simplePages/UsersInfo/customersInfo/CustomersInfo");
const MobileAppPage = UseLazy("simplePages/MobileAppPage");
const Agreement = UseLazy("simplePages/Agreement");
const UserTerm = UseLazy("simplePages/UserTerm");
const Contacts = UseLazy("components/contacts/Contacts");
const Partners = UseLazy("simplePages/partners/Partners");
const Privacy = UseLazy("simplePages/Privacy");
const Charity = UseLazy("simplePages/Charity");
const Rules = UseLazy("simplePages/Rules");
const About = UseLazy("components/about/About");
const Docs = UseLazy("simplePages/Docs");
const Blog = UseLazy("components/blog/Blog");
const FAQ = UseLazy("simplePages/FAQ/FAQ");
const MailConfirm = UseLazy("components/mailConfirm/MailConfirm");
const MainPage = UseLazy("components/index/MainPage");
const Article = UseLazy("components/blog/Article");
const ContractorProfile = UseLazy("components/catalogPages/ContractorProfile/ContractorProfile");
const PersonalData = UseLazy("simplePages/PersonalData");
const Catalog = UseLazy("components/catalog/Catalog");
const CartProduct = UseLazy("components/catalogPages/CartProduct/CartProduct");

export let token = "Bearer " + localStorage.getItem("token");
export let isAppstore = !!localStorage.getItem("app");
export let cityAlias = JSON.parse(localStorage.getItem("location"))?.alias;

if (!isAppstore) {
    if (window.location.href.indexOf("?app=1") > -1) {
        localStorage.setItem("app", 1);
        isAppstore = true;
    }
}

const App = () => {
    const location = useLocation();
    const locationIsCart = location.pathname.split('/')[1]

    useEffect(() => {
        document.querySelector('meta[name="og:description"]').setAttribute("content", "Сервис CityWork предлагает найти исполнителей для выполнения разных работ: грузоперевозки, капитальный ремонт, ремонт квартир, строительные работы, строительство домов, отделочные работы, аренда помещения, ремонт оборудования");
        document.querySelector('meta[name="description"]').setAttribute("content", "Сервис CityWork предлагает найти исполнителей для выполнения разных работ: грузоперевозки, капитальный ремонт, ремонт квартир, строительные работы, строительство домов, отделочные работы, аренда помещения, ремонт оборудования");
        document.querySelector('meta[name="og:title"]').setAttribute("content", `CityWork – сервис по поиску исполнителей или заказчиков в городе ${JSON.parse(localStorage.getItem("location"))?.city || 'Москва'}`);
        document.title = `CityWork – сервис по поиску исполнителей или заказчиков в городе ${JSON.parse(localStorage.getItem("location"))?.city || 'Москва'}`
    }, [location.pathname])

    useEffect(() => {
        fetch(`${link}/city/by-alias?alias=${locationIsCart}&expand=region, region.country`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    const data = {
                        country: result.data.region.country.name,
                        country_id: result.data.region.country.id,
                        region: result.data.region.name,
                        region_id: result.data.region.id,
                        city: result.data.name,
                        city_id: result.data.id,
                        alias: result.data.alias
                    };
                    localStorage.setItem("location", JSON.stringify(data));
                    if(locationIsCart !== cityAlias){
                        window.location.reload()
                    }
                }
            })
    }, [locationIsCart]);

    const [idProductPage, setIdProductPage] = useState([]);

    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);
    const modal2 = useContext(DeviceContext);

    const isModerator = ctx.userData?.account?.role === "moderator";
    const isAdModerator = ctx.userData?.account?.role === "ad_moderator";

    const isBankPage = window.location.href.includes("/bank");

    if (isModerator) {
        return (
            <ModeratorContextProvide>
                <Modal />
                <Routes>
                    <Route path="/moderator" element={<ModeratorLayout />}></Route>
                </Routes>
            </ModeratorContextProvide>
        );
    }else if(isAdModerator){
        return(
            <AdModeratorContextProvide>
                <Routes>
                    <Route path="/ad_moderator" element={<AdModeratorLayout />}></Route>
                </Routes>
            </AdModeratorContextProvide>
            )
    } else {
        return (
            <div
                className={isAppstore ? "copyBan" : undefined}
                style={{
                    flexGrow: 1,
                    display: "flex",
                    flexFlow: "column nowrap",
                    minHeight: "100vh",
                }}>
                {!isBankPage && <Header />}
                <Helmet>
                    <title>CityWork – сервис по поиску исполнителей или заказчиков в городе {JSON.parse(localStorage.getItem("location"))?.city}</title>
                </Helmet>
                <Modal id={idProductPage} />
                <NoInternetConnection>
                    <ApplicationMobileProvide>
                        <MobileApp />

                        <main
                            className={`main ${
                                modal.isCategories || modal.openModal === "searching" ? "fixedHeader" : ""
                            } ${window.location.pathname.includes("bank") ? "bank_check" : ""}`}>
                            <Routes>
                                <Route
                                    path="/administrants-info"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <AdministrantsInfo />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/customers-info"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <CustomersInfo />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/application"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <MobileAppPage />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/agreement"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Agreement />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/userTerm"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <UserTerm />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/contacts"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Contacts />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/partners"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Partners />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/privacy"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Privacy />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/charity"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Charity />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/rules"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Rules />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/about"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <About />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/docs"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Docs />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/blog"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Blog />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/faq"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <FAQ />
                                        </Suspense>
                                    }></Route>

                                <Route
                                    path="/mail-confirm"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <MailConfirm />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <MainPage />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/article/:id"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <Article />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/contractor/:id"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <ContractorProfile />
                                        </Suspense>
                                    }></Route>
                                <Route
                                    path="/personalData"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <PersonalData />
                                        </Suspense>
                                    }></Route>
                                <Route
                                        path="/marketing"
                                        element={<StubAds />}
                                ></Route>
                                <Route
                                    path="/referral"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <ReferralInfo />
                                        </Suspense>
                                    }></Route>

                                <Route
                                    path="/partner_program"
                                    element={
                                        <Suspense fallback={<Loader />}>
                                            <PartnerProgram />
                                        </Suspense>
                                    }></Route>

                                {/* Проверка на наличие токена и его действительность */}
                                {ctx.userData?.id && (
                                    <>
                                        <Route exact path="/lc/*" element={<CabinetMainPage />}></Route>
                                        {(ctx.userData?.account?.role === "admin" || ctx.userData?.account?.role === "fin_admin" || ctx.userData?.account?.role === 'tech') &&  (
                                                <Route path="/admin/*" element={<Admin />}></Route>
                                            )}
                                    </>
                                )}
                                <Route
                                    path={`/${cityAlias}/:alias/:fullAlias?/:maxAlias?/:bruhAlias?`}
                                    element={
                                        <Suspense fallback={<Loader />} key={location.key} >
                                            {/*Set the key to force re-render when it changes*/}
                                            {!window.location.href.includes('_') && window.location.href.includes(cityAlias) ?
                                                <Catalog />
                                                 :
                                                 <CartProduct getIdPage={setIdProductPage} />}
                                        </Suspense>
                                    }></Route>

                                <Route path="/advertiser/*" element={<Advertiser></Advertiser>}></Route>
                                {/* аутентификация по логину и паролю */}
                                <Route path="/admin-login" element={<AdminLogin></AdminLogin>}></Route>
                                <Route path="/search/*" element={<SearchPage />}></Route>
                                <Route path="/bank/:id" element={<BankCheck />}></Route>
                                <Route path="/404" element={<NotFound />}></Route>
                                <Route path="/login" element={<LoginMain />}></Route>
                            </Routes>
                        </main>
                    </ApplicationMobileProvide>
                </NoInternetConnection>
                {(!isBankPage /*&& !isAppstore */) && (modal2.openAside && !modal2.isMobile || !modal2.openAside && modal2.isMobile ) && <Footer />}
            </div>
        );
    }
};

export default App;
