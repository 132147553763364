import {useContext, useEffect, useState} from "react";
import DeviceContext from "../../store/device-context";
import AuthContext from "../../store/auth-context";
import s from "../../admin/aside/style.module.sass";
import {NavLink} from "react-router-dom";
import noAvatar from "../../assets/images/stubs/avatar.svg";
import {link, url} from "../../store/host-store";
import {ExitIcon} from "../../components/icons/ExitIcon";
import {AsideUser} from "../../components/icons/AsideUser";
import AdsIconSvg from "../../components/icons/adIcons/AdsIconSvg";
import AdLaunchSvg from "../../components/icons/adIcons/AdLaunchSvg";
import {token} from "../../App";
import {TwoPeoplesIcon} from "../../components/icons/TwoPeoplesIcon";
import BankCheckIcon from "../../components/icons/BankCheckIcon";
import {NotificationIcon} from "../../components/icons/NotificationIcon";
import {SupportIcon} from "../../components/icons/SupportIcon";
import {TermsIcon} from "../../components/icons/TermsIcon";

export const exitAccount = () => {
    localStorage.removeItem("token");
    window.location.replace("/");
};

const AdvertiserAside = () => {
    const ctx = useContext(AuthContext);
    const device = useContext(DeviceContext);
    const { userData } = useContext(AuthContext);
    const [haveAds, setHaveAds] = useState(false);

    useEffect(() => {
        fetch(`${link}/advertisement/user-advertisements?filter[status]=3?pagination=0`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        }).then(res => res.json())
            .then(res => {
                if(res.data.length > 0){
                    setHaveAds(true)
                }
            })
    }, [])


    return(
        <div className={`${s.aside__wrapper} aside_advertiser`} style={{height: 'fit-content'}}>
            <div className={s.user_info}>
                <button
                    type="button"
                    className="cabinet__aside_close"
                    title="открыть меню"
                    onClick={device.toggleAside}></button>
                <NavLink className="cabinet__person" to="/advertiser/" onClick={device.toggleAside}>
                    <div className="cabinet__img">
                        {userData.avatar === null ? (
                            <img src={noAvatar} alt="" />
                        ) : userData.webpFilename ? (
                            <img src={url + userData.webpFilename} alt="avatar"></img>
                        ) : (
                            <img src={url + userData.avatar} alt="avatar" />
                        )}
                    </div>

                    <div>
                        <p className="cabinet__name">{userData.name}</p>
                        <span style={{color: "#000"}}>ID {userData.id}</span>
                    </div>
                </NavLink>
            </div>
            <div className="cabinet__nav">
                    <>
                        <NavLink to="/advertiser/" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <AsideUser />
                            </div>
                            Главная
                        </NavLink>
                        {userData?.organization?.status === 1 &&
                        <>
                        <NavLink to="/advertiser/ad" className="cabinet__item_white cab_white_rect" onClick={device.toggleAside}>
                                <AdsIconSvg />
                            Размещение рекламы
                        </NavLink>
                        {/*<NavLink to={'/advertiser/launch'} className="cabinet__item_white cab_white_rect" onClick={device.toggleAside}>*/}
                        {/*    <AdLaunchSvg />*/}
                        {/*    Запуск рекламы*/}
                        {/*</NavLink>*/}
                        </>
                        }

                        <NavLink to="/lc/referral" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <TwoPeoplesIcon />
                            </div>
                            Бонусная программа
                        </NavLink>

                        <NavLink to="/lc/Checks" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <BankCheckIcon />
                            </div>
                            Бухгалтерские документы
                        </NavLink>

                        <NavLink to="/lc/notifications" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <NotificationIcon />
                            </div>
                            Уведомления
                            {ctx.notificationsCount > 0 && (
                                <span className="cabinet__item_counter cabinet__item_notif">{ctx.notificationsCount}</span>
                            )}
                        </NavLink>

                        <NavLink to="/lc/support" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <TermsIcon />
                            </div>
                            Служба поддержки
                        </NavLink>

                        <NavLink to="/lc/term" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <SupportIcon />
                            </div>
                            Условия использования
                        </NavLink>

                        <NavLink to="/lc/" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <AsideUser />
                            </div>
                            Вернутся в личный кабинет
                        </NavLink>

                    </>
            </div>
            <button type="button" className="exit_link admin__exit_link" onClick={exitAccount}>
                <ExitIcon />
                Выйти
            </button>
        </div>
    )
}

export default AdvertiserAside;