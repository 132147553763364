import { configureStore } from "@reduxjs/toolkit";
import checkboxAdversSlice from "./checkboxAdversSlice";
import adminCategories from "./admin-categories";
import adversStatus from "./adversStatus";
import calendar from "./calendar";
import catalog from "./catalog";
import blockerPage from "./blockerPage";
import adsSearchLogic from "./adsSearchLogic";
import promotion from "./promotion";
import notification from "./notification";
import moderators from "./admin/moderators";
import {seoApi} from "./queries/seo-categories";
import {setupListeners} from "@reduxjs/toolkit/query";
import {launchAdsApi} from "./queries/launch-advertisement";
import modalsSlice from "./modals/modalsSlice";
import {billsQuery} from "./queries/bills-query";
import {catalogQuery} from "./queries/catalog-query";
import {mtsQueries} from "./queries/mts/mts-queries";
import {partnerProgramQuery} from "./queries/partnerProgram/partner-program-query";
import referralSlice from "./referralAndPartner";
import {partnerProgramAdminQuery} from "./queries/partnerProgram/partner-program-admin-query";
import {partnerProgramPrices} from "./queries/partnerProgram/partner-program-prices";
import {supportMessage} from "./queries/support-message";

const store = configureStore({
    reducer: {
        adminCategories: adminCategories.reducer,
        checkboxes: checkboxAdversSlice.reducer,
        status: adversStatus.reducer,
        calendar: calendar.reducer,
        catalog: catalog.reducer,
        blockerPage: blockerPage.reducer,
        adsSearchLogic: adsSearchLogic.reducer,
        promotion: promotion.reducer,
        notification: notification.reducer,
        moderators: moderators.reducer,
        modalsSlice: modalsSlice.reducer,
        referralSlice: referralSlice.reducer,
        [seoApi.reducerPath]: seoApi.reducer,
        [launchAdsApi.reducerPath]: launchAdsApi.reducer,
        [billsQuery.reducerPath]: billsQuery.reducer,
        [catalogQuery.reducerPath]: catalogQuery.reducer,
        [mtsQueries.reducerPath]: mtsQueries.reducer,
        [partnerProgramQuery.reducerPath]: partnerProgramQuery.reducer,
        [partnerProgramAdminQuery.reducerPath]: partnerProgramAdminQuery.reducer,
        [partnerProgramPrices.reducerPath]: partnerProgramPrices.reducer,
        [supportMessage.reducerPath]: supportMessage.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([seoApi.middleware, launchAdsApi.middleware, billsQuery.middleware, catalogQuery.middleware, mtsQueries.middleware,
            partnerProgramQuery.middleware, partnerProgramAdminQuery.middleware, partnerProgramPrices.middleware, supportMessage.middleware])

});

export const checkboxAction = checkboxAdversSlice.actions;
export const adminAction = adminCategories.actions;
export const statusAction = adversStatus.actions;
export const calendarAction = calendar.actions;
export const catalogAction = catalog.actions;
export const blockerAction = blockerPage.actions;
export const adsSearchAction = adsSearchLogic.actions;
export const promoAction = promotion.actions;
export const notificationAction = notification.actions;
export const moderatorsAction = moderators.actions;
export const modalsSliceAction = modalsSlice.actions
export const referralSliceAction = referralSlice.actions;
setupListeners(store.dispatch)
export default store;
