import {link} from "../../store/host-store";
import {token} from "../../App";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../store/auth-context";

const AdvertiserOrganization = ({title0, title1, title2, setOrganization, organization}) => {
    const customOrganization = window.location.href.indexOf('advertiser') > - 1
    const auth = useContext(AuthContext)
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if(customOrganization){
            if(auth.isUserId !== 0) {
                fetch(`${link}/organization?filter[user_id]=${auth.isUserId}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    }
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.data.length > 0 && res.data[0].status !== 2) {
                            setOrganization(res.data[0]?.type)
                            setDisable(true);
                        }
                    })
            }
        }
    }, [auth.isUserId])

    console.log(organization, disable)

  return(
      <div className="flex_organization">
          {(!disable || (disable && organization === 0) )&& <div onClick={() => setOrganization(0)}
              className={`organization ${organization === 0 ? 'active' : ''}`}>{title0}</div>}
          {(!disable || (disable && organization === 1) )&& <div onClick={() => setOrganization(1)}
                className={`organization ${organization === 1 ? 'active' : ''}`}>{title1}</div>}
          {(!disable || (disable && organization === 2)) && <div onClick={() => setOrganization(2)}
                className={`organization ${organization === 2 ? 'active' : ''}`}>{title2}</div>}
      </div>
  )
}

export default AdvertiserOrganization;