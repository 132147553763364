const UserOrganizationInfo = ({data}) => {
    console.log(data)
    return (
        <div className="admin-user_moderated__bottom">
            <div className="admin-user_organization__block">
                <div className="admin-user_organization_title">Юридическое наименование: <span>{data?.name}</span></div>
            </div>

            <div className="admin-user_organization__block">
                <div className="admin-user_organization_title">Основной ОКВЭД: <span>{data?.okved !== null ? data?.okved : 'Не указанно'}</span></div>
            </div>

            <div className="admin-user_organization__block">
                <div className="admin-user_organization_title">ИНН: <span>{data?.inn}</span></div>
            </div>

            <div className="admin-user_organization__block">
                <div className="admin-user_organization_title">ОГРН: <span>{data?.ogrn}</span></div>
            </div>

            <div className="admin-user_organization__block">
                <div className="admin-user_organization_title">Юридический адрес: <span>{data?.address}</span></div>
            </div>
        </div>
    )
}

export default UserOrganizationInfo;