import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

import { ArrowLeftIcon } from "../../../../components/icons/ArrowLeftIcon";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import { EditIcon } from "../../../../components/icons/EditIcon";
import FormModal from "../../../../UI/Modal/FormModal/FormModal";
import Loader from "../../../../components/loader/Loader";
import { link } from "../../../../store/host-store";
import { token } from "../../../../App";
import s from "./style.module.sass";
import EditAdminPriceCity from "./EditAdminPriceCity";
import EditAdminQuantityAds from "./EditAdminQuantityAds";

const EditAdminAds = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [newRateModal, setNewRateModal] = useState(false);
    const [editRateModal, setEditRateModal] = useState(false);
    const [editId, setEditId] = useState(null);

    const type = '2';

    const [count, setCount] = useState("");
    const [price, setPrice] = useState("");
    const [lastPrice, setLastPrice] = useState("");
    const [name, setName] = useState("");

    const addField = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("type", type);
        // formData.append("price", price);
        // formData.append("old_price", lastPrice);
        formData.append("name", name);
        formData.append("duration", count);


        fetch(`${link}/tariff-plan`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                setNewRateModal(false);
                setRerender((prev) => !prev);
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        fetch(`${link}/tariff-plan?filter[type]=${type}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result)
                if (result.success) {
                    setData(result.data);
                    setLoading(false);
                }
            })
            .catch((e) => console.log(e));
    }, [rerender]);

    const onEdit = (id) => {
        setEditId(id);
        fetch(`${link}/tariff-plan/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setEditRateModal(true);
                setName(result.data.name);
                setPrice(result.data.price);
                setLastPrice(result.data.old_price);
                setCount(result.data.duration);
                console.log(result);
            })
            .catch((e) => console.log(e));
    };

    const onDelete = (id) => {
        fetch(`${link}/tariff-plan/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
            method: "DELETE",
        })
            .then((res) => res.json())
            .then(() => {
                setRerender((prev) => !prev);
            })
            .catch((e) => console.log(e));
    };

    const onSave = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append("type", type);
        // formData.append("price", price);
        // formData.append("old_price", lastPrice);
        formData.append("name", name);
        formData.append("duration", count);


        fetch(`${link}/tariff-plan/${editId}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
            method: "PUT",
            body: formData,
        })
            .then((res) => res.json())
            .then(() => {
                setEditRateModal(false);
                setRerender((prev) => !prev);
            })
            .catch((e) => console.log(e));
    };

    const onAdd = () => {
        setNewRateModal(true);
        setName("");
        setPrice("");
        setLastPrice("");
        setCount("");
    };

    return (
        <div className="admin__layout">
            {loading ? (
                <Loader />
            ) : (
                <div className={s.promo}>
                    {newRateModal && (
                        <FormModal
                            title="Добавить новый тариф"
                            button="Добавить"
                            fn={addField}
                            isOpenModal={setNewRateModal}>
                            <span>Название</span>
                            <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} />

                            <span>{type === 0 ? "Продолжительность" : "Количество"}</span>
                            <input type="number" value={count} onChange={(e) => setCount(e.currentTarget.value)} />

                            {/*<span>Стоимость</span>*/}
                            {/*<input type="number" value={price} onChange={(e) => setPrice(e.currentTarget.value)} />*/}

                            {/*<span>Старая стоимость</span>*/}
                            {/*<input*/}
                            {/*    type="number"*/}
                            {/*    value={lastPrice}*/}
                            {/*    onChange={(e) => setLastPrice(e.currentTarget.value)}*/}
                            {/*/>*/}
                        </FormModal>
                    )}
                    {editRateModal && (
                        <FormModal title="Редактировать" button="Сохранить" fn={onSave} isOpenModal={setEditRateModal}>
                            <span>Название</span>
                            <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} />

                            <span>{type === 0 ? "Продолжительность" : "Количество"}</span>
                            <input type="number" value={count} onChange={(e) => setCount(e.currentTarget.value)} />

                            {/*<span>Стоимость</span>*/}
                            {/*<input type="number" value={price} onChange={(e) => setPrice(e.currentTarget.value)} />*/}

                            {/*<span>Старая стоимость</span>*/}
                            {/*<input*/}
                            {/*    type="number"*/}
                            {/*    value={lastPrice}*/}
                            {/*    onChange={(e) => setLastPrice(e.currentTarget.value)}*/}
                            {/*/>*/}
                        </FormModal>
                    )}
                    <div className={s.top}>
                        <div className={s.left}>
                            <NavLink className="admin__back-arrow" to="/admin/promotion">
                                <ArrowLeftIcon />
                            </NavLink>
                            <h3>Редактировать тариф рекламы</h3>
                        </div>
                        <div className={s.right}>
                            <button type="button" className="admin__btn-blue" onClick={() => onAdd(true)}>
                                Добавить
                            </button>
                        </div>
                    </div>
                    <div className={s.bottom}>
                        <div className={s.titles}>
                            <p>Название</p>
                            <p>Дни</p>
                            {/*<p>Цена со скидкой</p>*/}
                            {/*<p>Цена до скидки</p>*/}
                        </div>
                        {data.map((el) => {
                            return (
                                <div key={el.id} className={s.rates} style={{borderRadius: 0}}>
                                    <p>{el.name}</p>
                                    <p>{el.duration}</p>
                                    {/*<p>{el.price}</p>*/}
                                    {/*<p>{el.old_price}</p>*/}
                                    <div className={s.edit} onClick={() => onEdit(el.id)}>
                                        <EditIcon />

                                    </div>
                                    <div className={s.edit} onClick={() => onDelete(el.id)}>
                                        <CloseIcon />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            <EditAdminPriceCity />
            {/*<EditAdminQuantityAds />*/}
        </div>
    );
};

export default EditAdminAds;
