import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../store/auth-context";
import {useForm} from "react-hook-form";
import {link} from "../../../store/host-store";
import {token} from "../../../App";

const OneUserFormAccept = ({organization, id, organizationStatus}) => {
    const [data, setData] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [errors1, setErrors] = useState('');
    const ctx = useContext(AuthContext);

    console.log(organizationStatus)

    const {register, formState: {errors}, handleSubmit} = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        values: {
            name: data?.type ? data?.name : '',
            inn: data?.type ? data?.inn : '',
            ogrn: data?.type ? data?.ogrn : '',
            address: data?.type ? data?.address : '',
            okved: data?.type ? data?.okved : '',
        }
    });

    console.log(data?.name)

    useEffect(() => {
        fetch(`${link}/user/${id}?expand=organization`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        })
            .then(res => res.json())
            .then(res => {
                setData(res.data?.organization)
                console.log(res.data)
            })
    }, [organization, rerender, ctx.isUserId])

    const onSubmit = (d) => {
        fetch(`${link}/organization/${data.id}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(d)
        }).then(res => res.json())
            .then(res => console.log(res.data))
            .then(() => {
                setRerender(prevState => !prevState)
                window.location.reload()
            })
    }

  return(
      <fieldset className="fieldset_admin">
          <form onSubmit={handleSubmit(onSubmit)} className="person_data person_data_admin">
              <div>
                  <div className="flex_inn_ogrn">
                      <div className="block_org">
                          <div
                              className="adv_cabinet_title">Юридическое наименование:</div>
                          <div className="input" style={{marginBottom: '12px'}}>
                              <input type="text" placeholder='Введите название'
                                     {...register('name' ,{ validate: value => !!value.trim() ,required: 'Поле не должно быть пустым',
                                         pattern: {
                                             value: organization === 1 ? /^([^0-9]*)$/ : '',
                                             message: 'Должен содержать как минимум один пробел'
                                         }
                                     })}/>
                              {/*<input key={organization} type="text" defaultValue={ld} onChange={() => setLD(organization)} {...register('type', {value: organization})}/>*/}
                              {errors?.name ? <p className='error'>{errors?.name.message}</p> : ''}
                              {errors?.name?.type === 'validate' ? <p className='error'>Поле не должно быть пустым</p> : ''}
                          </div>
                      </div>

                      <div className="block_org">
                          <div
                              className="adv_cabinet_title">Основной ОКВЭД:</div>
                          <div className="input" style={{marginBottom: '12px'}}>
                              <input type="text" pattern="[0-9.]+" placeholder='Введите ОКВЭД' {...register('okved')}/>
                              {errors?.okved ? <p className='error'>{errors?.okved.message}</p> : ''}
                          </div>
                      </div>

                  </div>
                  <div className="flex_inn_ogrn">
                      <div className="block_org">
                          <div className="adv_cabinet_title">ИНН:</div>
                          <div className="input">
                              <input type="text"  pattern="[0-9]+" placeholder="Введите ИНН"
                                     {...register('inn', {
                                         required: 'Поле не должно быть пустым',
                                         minLength: organizationStatus === 1 ? {value: 12, message: 'Минимум 12 цифр'} : {value: 10, message: 'Минимум 10 цифр'},
                                         maxLength: organizationStatus === 1 ? {value: 12, message: 'Максимум 12 цифр'} : {value: 10, message: 'Максимум 10 цифр'},
                                     })}
                              />
                              {errors?.inn ? <p className='error'>{errors?.inn.message}</p> : ''}
                          </div>
                      </div>
                      {organization !== 0 &&
                          <div className="block_org">
                              <div className="adv_cabinet_title">ОГРН</div>
                              <div className="input">
                                  <input type="number" placeholder="Введите ОГРН" {...register('ogrn', {
                                      required: 'Поле не должно быть пустым',
                                      minLength: {value: 13, message: 'Минимум 13 цифр'},
                                      maxLength: {value: 15, message: 'Максимум 15 цифр'},
                                  })}/>
                                  {errors?.ogrn ? <p className='error'>{errors?.ogrn.message}</p> : ''}
                              </div>
                          </div>
                      }
                  </div>
                  <div
                      className="adv_cabinet_title">{organization === 2 ? 'Юридический адрес:' : 'Адрес места жительства:'}</div>
                  <div className="input">
                      <input type="text" placeholder="Введите адрес" {...register('address')}/>
                  </div>
                  <p className='error'>{errors1.message}</p>
                  <button type='submit' style={{marginTop: "14px"}} onClick={() => console.log(errors)}
                          className='btn_custom'>Сохранить
                  </button>
              </div>
          </form>
      </fieldset>
  )
}

export default OneUserFormAccept;